body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Yannis' CSS */ 

@charset "UTF-8";
img{display:block}
body {
font-family: 'Georgia';
color :black;
}

.stork{
  color :black;
  font-size : 300%;
  display: inline-block;
  color: hsl(0, 0, 50);
  margin-top: 30%;
  margin-left: 2em;
}

.filmsnav {
font-family: 'Courier New', 'Courier', 'monospace';
color :black;
}

.books {
  color :black;
font-family: 'Bodoni MT', 'Didot', 'Garamond';
}

.header p ,ul, li{ display:inline-block;}

.nav{color :black;
margin-left: 26%;}

.nav li,a{
  color :black;
  margin : 0.5em;
}

a:link { text-decoration: none;
  color: hsl(0, 0, 50);}
a:visited {
    text-decoration: none;}

    .films{
      font-family: 'Courier New', 'Courier', 'monospace';
      color :black;
      margin-left: 16%;
      margin-top: 5%;
      }
      
      .films li{display:inline-block;
        margin-right: 10em;}
        
.film {
  font-family: 'Courier New', 'Courier', 'monospace';
  color :black;
  margin-left: 10%;
  margin-top: 5%;
  }
  .film li{display: inline-block;
    color:black}